import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import truncate from "truncate-html"
import { AgilityImage } from "@agility/gatsby-image-agilitycms"
import { Search } from "@organic-return/foundation-react/src/components/Search/Search"
import { Choose, Text } from "@organic-return/foundation-react/src/components/Search/Filter"
import { ISearchContext } from "@organic-return/foundation-react/src/components/Search/SearchContext"

const PostListing = ({ module }) => {
  // graphql query for posts data
  const data = useStaticQuery(graphql`
    query {
      posts: allAgilityPost(
        filter: { properties: { referenceName: { eq: "posts" } } }
        sort: { fields: customFields___date, order: DESC }
      ) {
        nodes {
          customFields {
            title
            content
            date(formatString: "MMMM DD, YYYY")
            image {
              url
              height
              width
            }
            category {
              contentid
            }
          }
          sitemapNode {
            path
          }
          category: linkedContent_agilityCategory {
            customFields {
              title
            }
          }
        }
      }
    }
  `)

  // get posts
  const posts = data.posts.nodes

  const dateFormatOptions = {
    month: "long",
    day: "numeric",
  }

  //Fixes key issue
  const indexPosts = posts.map((post, index) => {
    return {
      index,
      post,
    }
  })

  const latestPosts = posts && (
    <div className="flex flex-col w-full shadow-lg">
      {posts && posts.slice(0, 8).map((post, index) => {
        return (
          <div key={index} className="w-full text-sm p-5 flex flex-row justify-start items-center odd:bg-body-bg even:bg-[#F9FAFF] hover:bg-accent text-neutral-500 hover:text-white">
            <span>&gt;</span>
            <Link to={post.sitemapNode.path} className="pl-4 text-inherit hover:text-white">
              {post.customFields.title}
            </Link>
          </div>
        )
      })}
    </div>
  )

  let postsCategories: String[] = []
  posts && posts.length > 0 && (
    posts.forEach((post) => {
      if (post.category?.customFields?.title &&
        !postsCategories.includes(post.category.customFields.title)) {
        postsCategories.push(post.category.customFields.title)
      }
    })
  )

  const categories = postsCategories.length > 0 && (
    <div className="text-xs w-full flex flex-row flex-wrap bg-body-bg p-7 gap-3 shadow-lg">
      {postsCategories.map((category, index) => {
        return (
          <Link to={`${encodeURI(`#filters={"category":"${category}"}`)}`} key={index} className=" text-inherit py-2 px-4 rounded-[20px] bg-neutral-100">
            {category}
          </Link>
        )
      })}
    </div>
  )

  const callToAction = module.customFields?.cTALink?.href && (
    <div className="w-full inverted px-7 py-12 shadow-lg">
      {module.customFields?.cTAText && (
        <div className="text-lg text-center pb-4" dangerouslySetInnerHTML={{ __html: module.customFields.cTAText }}>
        </div>
      )}
      <Link
        to={module.customFields.cTALink.href}
        className="block text-center"
        target={module.customFields.cTALink.target}
      >
        <button className="blog-button italic bg-action text-body-text-inverted px-8 py-2 ">
          {module.customFields.cTALink.text}
        </button>
      </Link>
    </div>
  )

  const primaryFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          context={context}
          inputClassName="text-sm rounded-none bg-transparent placeholder-composite-text border-composite-text pl-0"
          label="Blog search"
          placeholder="Blog search"
          param="text"
          resolver={(arg: any, text) => {
            let argl = arg.post.customFields;
            let match = text.toLowerCase();
            return (argl.title && argl.title.toLowerCase().includes(match)) ||
              (argl.content.text &&
                argl.content.text.toLowerCase().includes(match))
              ? true
              : false;
          }}
        />
        <Choose
          className="hidden"
          context={context}
          placeholder="Category"
          label="Category"
          param="category"
          resolver={arg => arg.post.category?.customFields?.title}
          match="one"
        />
        {latestPosts}
        {callToAction}
        {categories}
      </>
    )
  }

  // if there are no posts, display message on frontend
  if (posts.length <= 0) {
    return (
      <div className="mt-44 px-6 flex flex-col items-center justify-center">
        <h1 className="text-3xl text-center font-bold">No posts available.</h1>
        <div className="my-10">
          <Link
            to="/"
            className="px-4 py-3 my-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary transition duration-300"
          >
            Return Home
          </Link>
        </div>
      </div>
    )
  }

  return (
    <Search
      index={indexPosts}
      pageSize={4}
      pagerBottom={true}
      showCount={false}
      className="max-w-6xl w-full mx-auto px-4 bg-gray-100 blog-search"
      resultsClassName="sm:block columns-1 md:gap-10"
      primaryFilters={primaryFilters}
      renderItem={(item, index) => {
        const post = item.post
        // truncate post content
        const description = truncate(post?.customFields.content, {
          length: 160,
          decodeEntities: true,
          stripTags: true,
          reserveLastWord: true,
        })

        return (
          <div
            key={item.index}
            className={`bg-white block w-full md:w-auto break-inside-avoid mb-10 shadow-lg`}
          >
            <Link to={post.sitemapNode.path} className="relative">
              <AgilityImage
                image={post.customFields.image}
                className="rounded-t-lg"
                width={750}
                height={440}
                layout="fullWidth"
                loading="eager"
              />
              <div className="absolute top-4 left-4 text-body-text-inverted bg-body-bg-inverted px-2 py-1 text-sm">
                {new Date(post.customFields.date).toLocaleDateString(
                  "en-US",
                  dateFormatOptions
                )}
              </div>
            </Link>
            <div className="p-8">
              <h2 className="capitalize font-normal tracking-normal text-2xl text-center">
                {post.customFields.title}
              </h2>
              <p className="text-body-text text-center text-neutral-800">
                {description}
              </p>
              <Link
                to={post.sitemapNode.path}
                key={index}
                className="block text-center"
              >
                <button className="blog-button italic bg-action text-body-text-inverted px-9 py-2 ">
                  Read More
                </button>
              </Link>
            </div>
          </div>
        )
      }}
      preRenderItem={(item, index) => {
        return (
          <a key={index} href={item.sitemapNode?.path}></a>
        )
      }}
    />
  )
}

export default PostListing
